:root {
  --color-primary: 241 90 36;
  --color-hover: 224 69 14;
  --color-gray-primary: 107 114 128;

  --color-secondary: 55 65 81;
  --color-scroll-bg: #d0d7de;
  --color-scroll: rgb(244 244 245);
  --color-third: #000;

  /* new */
  --color-success: 22 163 74; /* green-600 */
  --color-danger: 220 38 38; /* red-600 */
  --color-info: 37 99 235; /* info-600 */
  --color-warning: 234 179 8; /* yellow-500 */
  --color-border-primary: 229 231 235; /** gray-200 */
  --color-donate: 113 92 246;
  --color-donate-hover: 124 58 237;

  --color-form: 243 244 246;
  --color-form-hover: 229 231 235; /* gray-200 */
  --color-custom-gray: 128 128 128;
  --color-muted: 148 152 154;
  --color-text: 58 58 58;
  --color-light: 255 255 255;
}

.dark {
  --color-primary: 251 140 0;
  --color-hover: 241 90 36;
  --color-gray-primary: 153 153 153;

  --color-secondary: 210 210 210;
  --color-scroll-bg: #303030;
  --color-scroll: #141414;

  --color-third: #f5f5f5;

  /* new */
  --color-success: 21 128 61; /* green-700 */
  --color-danger: 239 68 68; /* red-700 */
  --color-info: 29 78 216; /* info-700 */
  --color-warning: 202 138 4; /* yellow-600 */
  --color-border-primary: 48 48 48; /** black-500 */

  --color-form: 24 24 24;
  --color-form-hover: 15 15 15;
  --color-custom-gray: 128 128 128;
  --color-muted: 118 114 115;
  --color-text: 255 255 255;
  --color-light: 31 31 31;
}

a,
button {
  -webkit-tap-highlight-color: transparent;
}

/* focus-visible:ring focus-visible:ring-[rgb(var(--color-primary))] */
.hover {
  @apply transition duration-150 ease-in-out focus:outline-none;
  -webkit-tap-highlight-color: transparent;
}

.popover {
  @apply ring-1 dark:ring-black-500 ring-gray-200/50 outline-none shadow-modal bg-white dark:bg-black-600 z-10;
}

.modal {
  @apply ring-1 rounded-lg dark:ring-black-700/50 ring-gray-200/50 shadow-modal bg-white dark:bg-black-700 z-40 text-black-1000 dark:text-gray-75;
}

.dark body {
  background-color: var(--color-bg);
}

input {
  outline: none !important;
  box-shadow: none !important;
}

.scrolling a {
  @apply pointer-events-none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari, and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

/* bugs for future images https://nextjs.org/docs/api-reference/next/future/image  */
@media not all and (min-resolution: 0.001dpcm) {
  img[loading='lazy'] {
    clip-path: inset(0.5px);
  }
}

input[type='search']::-webkit-search-cancel-button {
  /* Remove default */
  height: 20px;
  width: 20px;
  cursor: pointer;
  background-image: url(/icons/close.svg);
  background-size: 20px 20px;
  /* Will place small red box on the right of input (positioning carries over) */
}

::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

.small-scroll::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--color-scroll);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: var(--color-scroll-bg);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(var(--color-primary));
}

html {
  min-height: 100%;
}

.img:after {
  transition: opacity 125ms ease-in-out;
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  pointer-events: none;
  z-index: 1;
  border-radius: 0;
}

#header {
  transition:
    box-shadow 225ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 450ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.hidden-menu {
  transform: translateY(100%);
}

.icon-transform {
  -webkit-transition: -webkit-transform 0.25s ease;
  transition: -webkit-transform 0.25s ease;
  transition: transform 0.25s ease;
  transition:
    transform 0.25s ease,
    -webkit-transform 0.25s ease;
}

div[class*='-control'] {
  border-width: 1px;
  cursor: pointer;
  min-height: 38px;
}

div[class*='-NoOptionsMessage'] {
  font-weight: 300;
  font-size: 0.875rem;
  text-align: left;
  box-sizing: border-box;
}

.dark div[class*='-NoOptionsMessage'] {
  color: rgb(210 210 210);
}

.light div[class*='-multiValue'] {
  background-color: #e5e7eb;
}

.content-block {
  min-height: calc(100vh - 48px);
}

div[class*='-placeholder'] {
  font-weight: 300;
  font-size: 0.875rem;
}

.dark [type='checkbox']:checked {
  background-image: url("data:image/svg+xml,%3Csvg%20viewBox='0%200%2016%2016'%20fill='black'%20xmlns='http://www.w3.org/2000/svg'%3E%3Cpath%20d='M12.207%204.793a1%201%200%20010%201.414l-5%205a1%201%200%2001-1.414%200l-2-2a1%201%200%20011.414-1.414L6.5%209.086l4.293-4.293a1%201%200%20011.414%200z'/%3E%3C/svg%3E");
}
.content-blur img {
  filter: blur(10px);
}

input[type='range'] {
  background-image: linear-gradient(
    rgb(var(--color-primary) / 0.8),
    rgb(var(--color-primary) / 1)
  );
  background-repeat: no-repeat;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: rgb(var(--color-primary));
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

.adv-block div {
  margin: 0 auto;
}

.react-tooltip {
  @apply !text-white !bg-black-900;
}

.dark .react-tooltip {
  @apply !bg-black-800;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: rgb(var(--color-primary));
}

input:focus + .slider {
  box-shadow: 0 0 1px rgb(var(--color-primary));
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/** select2 **/
[class$='MultiValueRemove']:hover {
  @apply !bg-primary/[0.85] !text-text dark:!text-text/[0.8];
}

[class$='option']:active {
  @apply !bg-form-hover !text-primary/[0.85];
}

[type='text']:focus:where([id^='react-select']) {
  @apply ring-0 border-none;
}

input.input {
  height: 38px;
  font-size: 14px;
}

[multiple],
[type='date'],
[type='datetime-local'],
[type='email'],
[type='month'],
[type='number'],
[type='password'],
[type='search'],
[type='tel'],
[type='text']:where(:not([id^='react-select'])),
[type='time'],
[type='url'],
[type='week'],
input:where(:not([type])),
select,
textarea {
  @apply border focus:border-primary rounded-md border-[rgb(var(--color-border-primary)_/_0.5)] bg-[rgb(var(--color-form))];
}

.tooltip-text .primary {
  color: #f15a24;
}
.dark .tooltip-text .primary {
  color: #fb8c00;
}

/* Для Chrome, Safari, Edge и Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Для Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
